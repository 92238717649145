import "./assets/scss/Landing.scss";
import "./assets/scss/Icons.scss";

import { Navigate, Route, Routes } from "react-router-dom";

import React from "react";

const Landing = React.lazy(() => import("./pages/landing/"));

function App() {
  return <Landing />;
  // return (
  //   <React.Fragment>
  //   <Routes>
  //     <Route>
  //     </Route>
  //     </Routes>
  //   </React.Fragment>
  // );
}

export default App;
